import React from "react";
import { navigate } from "gatsby"

function IndexPage() {
  React.useEffect(() => {
    navigate('/docs/box/api/reference/')
  }, [])
  return (
    null
  );
}

export default IndexPage;
